import { isProd, isStage } from 'shared/utils/consts';

export const firebaseConfig = (() => {
  let config = process.env.REACT_APP_FIREBASE_DEV_CONFIG;
  if (isStage()) {
    config = process.env.REACT_APP_FIREBASE_STAGE_CONFIG;
  } else if (isProd()) {
    config = process.env.REACT_APP_FIREBASE_PROD_CONFIG;
  }
  console.log(`parsing: ${config}`);
  return JSON.parse(config || '{}');
})();
